import { useUserInfo } from '~/stores/user_info'

export default defineNuxtRouteMiddleware(async (to) => {
  const { loggedIn } = useAuth()

  if (!loggedIn) { return navigateTo('/login') }

  if (import.meta.server) { return }

  const requiredPermissions: string[] = (to.meta.permissions as string[]) || []
  if (requiredPermissions.length === 0) { return true }

  const userInfoStore = useUserInfo()
  if (!userInfoStore.fetched) {
    await userInfoStore.fetch()
  }

  if (!userInfoStore.hasPermission(requiredPermissions)) {
    return navigateTo('/?error=un-authorized')
  }
})
